@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pagination-controls {
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 50px;
}

.pagination-controls .pagination-controls__button {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #728899;
  font-family: 'Raleway';
  font-size: 14px;
  font-weight: 400;
  padding: 0 1em;
  margin-left: 2px;
  margin-right: 2px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
}

.pagination-controls .pagination-controls__button:first-child {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pagination-controls .pagination-controls__button:last-child {
  margin-right: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.pagination-controls .pagination-controls__button:hover:not(:active) {
  transition: -webkit-transform 0.15s;
  transition: transform 0.15s;
  transition: transform 0.15s, -webkit-transform 0.15s;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}

.pagination-controls .pagination-controls__button--active {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  background: #2785F2;
  padding: 10px 20px;
}

.pagination-controls .pagination-controls__button--active:hover, .pagination-controls__button--active:hover:not(:active) {
  -webkit-transform: none;
          transform: none;
}

a {
  text-decoration: none;
  color: #262626;
}

.MuiMenuItem-root {
  color: black;
}
